*
{
    margin: 0px;
    padding : 0px;
}

.soccer-field-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-right: 250px;

    background-color: #131313;
}

.soccer-field-top-wrapper
{
    height: 90%;
    width: 80%;

    background-image: url("../../../assets/board-bg.png");
    background-repeat: no-repeat;
    background-size: 1000px 560px;
    background-position: center;

    margin-top: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.soccer-field-grid-wrapper
{
    height: 430px;
    width: 1000px;

    margin-left: 13px;
    margin-top: 10px;

    display: grid;
    grid-template-columns: 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px 67px;
    grid-template-rows: 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px;
}

.soccer-field-bottom-wrapper
{
    height: 60%;
    width: 80%;

    margin-top: -60px;
}

.soccer-field-bottom-badges-wrapper
{
    height: 200px;
    width: 100%;

    background-image: url("../../../assets/badgesMenuBg.svg");
    background-repeat: no-repeat;
    background-size: 1000px 200px;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: scroll;
}

.soccer-field-bottom-wrapper button
{
    height: 51px;
    width: 235px;

    border-radius: 100px;

    color: white;
}

.save-button
{
    background: linear-gradient(#00C2FF, #044CAB);

    border: none;
    outline: none;

    margin-right: 23px;
}

.not-save-button
{
    background-color: rgb(67, 66, 66);

    border: none;
    outline: none;

    margin-right: 23px;
}

.soccer-field-bottom-wrapper svg
{
    height: 20px;
    width: 20x;
}

.not-save-button:focus
{
    outline: none;
}

.save-button:focus
{
    outline: none;
}

.new-button
{
    background: none;
    
    outline: none;
    border: 2px solid #0350AE;

    margin-left: 23px;
}

.new-button:focus
{
    outline: none;
}

.soccer-field-bottom-badges-list-wrapper
{
    height: 150px;
    width: 970px;

    display: grid;
    grid-template-columns: repeat(14, 69px);
    grid-template-rows: repeat(2, 75px);

    padding: 20px;
}

.soccer-field-bottom-badges-list-wrapper p
{
    font-size: 18px;
    color: white;
}

@media screen and (max-height: 920px)
{
    .soccer-field-bottom-wrapper
    {
        margin-top: 10px;
    }
}