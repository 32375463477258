*
{
    margin: 0px;
    padding : 0px;
}

.player-button-add-wrapper
{
    height: 93px;
    width: 256px;

    border-radius: 11px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    border: 1px solid white;

    color: white;
}

.player-button-add-wrapper p
{
    margin-top: 13px;
}