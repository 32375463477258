*
{
    margin: 0px;
    padding : 0px;
}

:root
{
    --border-radius-left : 100px;
    --border-radius-right : 100px;
}

.player-list-item-wrapper
{
    height: 90px;
    width: 256px;

    margin-top: 20px;
    
    border-top-left-radius: var(--border-radius-left);
    border-bottom-left-radius: var(--border-radius-right);

    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    background-color: #131313;
}

.player-list-item-wrapper-box
{
    height: 100%;
    width: 100%;
    
    border-top-left-radius: var(--border-radius-left);
    border-bottom-left-radius: var(--border-radius-right);

    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    background-color: #131313;
}

.player-list-item-remove
{
    height: 20px;
    width: 20px;

    position: absolute;
    top: 33px;
    right: -23px;

    border: none;
    background: none;

    color: red;
}

.player-list-item-remove:focus
{
    outline: none;
}

.wrapper
{
    width: 150px;
    height: 100%;

    margin-left: 90px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 100px;
}

.player-list-item-flag
{
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper-right
{
    width: 90px;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    overflow: hidden;
    
    max-width: 100px;
}

.stars
{
    max-height: 15px;
    max-width: 50px;
}

.profile
{
    height: 90px;
    width: 90px;

    position: absolute;
    left: -8px;
    top: -2px;

    border-radius: 100px;

    background-color: white;
}

.player-list-item-wrapper p
{
    height: 6px;
}

.index-player-wrapper
{
    height: 21px;
    width: 21px;

    border-radius: 100px;

    color: white;

    position: absolute;
    left: 5px;
    top: 75px;

    text-align: center;

    background-color: #07CE00;
}

.player-list-item-wrapper-selected
{
    border: 2px solid #35a53183;
}

.selected
{
    width: 100%;
}