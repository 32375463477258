*
{
    margin: 0px;
    padding: 0px;
}

.commentaire-input-wrapper
{
   height: 120px;
   width: 288px;

   border-radius: 30px;

   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   flex-direction: column;

   background-color: #2D2D2D;
}

.commentaire-input-wrapper input
{
    height: 50px;
    width: 288px;

    text-align: center;

    background: none;
    border: none;
    outline: none;

    color: white;
}

.commentaire-input-wrapper button
{
    height: 42px;
    width: 98px;

    border-radius: 100px;

    border: none;

    margin-right: 20px;
    margin-bottom: 15px;

    color: white;
}

.commentaire-input-wrapper-button-enable
{
    background: linear-gradient(#00C2FF, #044CAB);
}

.commentaire-input-wrapper-button-desable
{
    background: grey;
}

.commentaire-input-wrapper button:focus
{
    outline: none;
}