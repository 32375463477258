*
{
    margin: 0px;
    padding: 0px;
}

.menu-container-wrapper
{
    height: 100%;
    width: 323px;

    position: absolute;
    bottom: 0px;
    left: 102px;

    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    border-left: 6px solid #252832;

    background-color: #1C1C1C;

    border-top: 1px solid white;
    border-right: 1px solid white;

    z-index: 2;
}

.menu-container-wrapper-hide
{
    display: none;
}

.menu-container-wrapper-show
{
    display: block;
}

.badges-list-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}