.badges-item-wrapper
{
    height: 55px;
    width: 55px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    color: white;

    position: relative;

    max-width: 45px;
}

.test-item
{
    height: 30px;
    width: 55px;

    min-height: 30px;
    font-size: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badges-item-wrapper button
{
    height: 20Px;
    width: 15px;

    border: none;
    background: none;

    color: red;

    position: absolute;
    top: -15px;
    right: -10px;

    font-size: 14px;
}

.badges-item-wrapper button:focus
{
    border: none;
    outline: none;
}