body {
  margin: 0;
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, #root, #root>.App {
  margin: 0;
  height: 100%;
}