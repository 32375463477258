*
{
    margin: 0px;
    padding : 0px;
}

.player-list-wrapper
{
    height: 85%;
    width: 350px;

    position: absolute;
    right: 0px;
    bottom: 0px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    border-top: 1px solid white;
    border-left: 1px solid white;

    display: flex;
    justify-content: flex-end;

    background-color: #1C1C1C;
}

.test-droppable
{
    width: 325px;
    height: 120px;

    text-align: center;

    padding: 20px 30px px 30px;
}

.player-list-content-wrapper
{
    height: 100%;
    width: 325px;

    position: relative;

    border-left: 1px solid white;
}

.player-list-scroll
{
    height: 75%;
    width: 100%;

    overflow-y: scroll;

    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.draggable-player-list
{
    height: 120px;
    width: 100%;

    padding-left: 40px;

    min-width: 100%;
    min-height: 120px;
}

.player-add-wrapper
{
    width: 100%;
    height: 176px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-bottom: 2px solid white;

    color: #00B9F8;
}