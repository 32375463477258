*
{
    margin: 0px;
    padding : 0px;
}

.player-list-container-wrapper
{
    height: 100%;
    width: 500px;

    position: absolute;
    top: -1px;
    right: 325px;

    border-left: 1px solid white;
    border-top: 1px solid white;

    border-top-left-radius: 30px;

    background-color: #1C1C1C;

    z-index: 2;
}

.button-selected-close
{
    height: 35px;
    width: 38px;

    position: absolute;
    bottom: 70px;
    left: -20px;

    background: none;
    outline: none;
    border: none;
}

.profile-container
{
    height: 100px;
    width: 100px;

    border-radius: 100px;

    background-color: white;
}

.player-list-container-wrapper button:focus
{
    outline: none;
}

.player-list-container-profile
{
    height: 20%;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.badge-container
{
    height: 50px;
    width: 50px;

    margin-right: 15px;
}

.player-list-container-information
{
    height: 30%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.player-profile-stars
{
    margin-top: 20px;

    max-width: 120px;
    max-height: 20px;
}

.player-list-container-button
{
    height: 103px;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.player-list-container-utils
{
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.player-list-container-badges
{
    height: 15%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: white;

    font-size: 18px;
}

.player-list-container-badges-list
{
    height: 65%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: scroll;
    
    background-color: black;
}

.player-list-container-display
{
    height: 10%;
    width: 100%;

    color: white;

    padding-top: 10px;
}

.player-list-container-information table
{
    width: 90%;
    height: 90%;
}

.player-list-container-information-left
{
    padding-left: 30px;
    text-align: left;   
    border-bottom: 1px solid white;

    font-size: 14px;

    width: 120px;
    color: #707070;
}

.player-list-container-information-right
{
    text-align: right;   
    border-bottom: 1px solid white;

    font-size: 14px;

    width: 70%;
    color: white;
}

.button-display-wrapper
{
    width: 150px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.button-display-wrapper button
{
    height: 25px;
    width: 25px;

    border: none;
    background: none;

    margin-right: 15px;
}

.player-list-container-display-wrapper-button
{
    height: 30px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 110px);
    grid-template-rows: repeat(2, 40px);
}

.player-list-container-profile-size
{
    width: 100px;
    height: 100%;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}