*
{
    margin: 0px;
    padding: 0px;
}

.menu-item-wrapper
{
    height: 90px;
    width: 90%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    font-size: 14px;

    color: white;

    border-left: 3px solid transparent;

    padding-right: 3px;
} 

.menu-item-selected
{
    border-left: 3px solid #0350AE;
}