@media only screen and (max-width: 600px) {
    #bg {
        display: none;
    }
    #bg_mobile {
         /* Set rules to fill background */
         min-height: 100%;
         min-width: 100px;
         /* Set up proportionate scaling */
         width: 100%;
         height: auto;
         /* Set up positioning */
         position: fixed;
         top: 0;
         left: 0;
         z-index: -1;
        /* object fit */
        object-fit: contain;
        object-position: center bottom;
        background-color: black;
    }
}