.popup-search-wrapper
{
    height: 45px;
    width: 635px;

    border-radius: 23px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-right: 20px;

    background-color: #2D2D2D;

    position: relative;
}

.popup-search-wrapper input
{
    height: 100%;
    width: 100%;

    border: none;
    background: none;

    padding-left: 30px;

    color: white;
}

.popup-search-wrapper input:focus
{
    outline: none;
    border: none;
}

.popup-search-wrapper-content
{
    position: absolute;

    top: 50px;
    left: 0px;

    z-index: 5;

    height: auto;
    width: 635px;

    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.custom-hit-wrapper
{
    background-color: #2D2D2D;
}