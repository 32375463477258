@media only screen and (min-width: 600px) {
    #bg {
        /* Set rules to fill background */
        min-height: 100%;
        min-width: 1024px;
        /* Set up proportionate scaling */
        width: 100%;
        height: auto;
        /* Set up positioning */
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    #bg_mobile {
        display: none;
    }
}