.drop-zone-wrapper
{
    width: 30px;
    height: 30px;

    border-radius: 100px;

    position: relative;
}

.drop-zone-wrapper-enable
{
    border: 1px solid #1880e0;
}

.drop-zone-wrapper-desable
{
    border: 1px solid white;
}

.player-wrapper
{
    height: 30px;
    width: 30px;

    position: absolute;
    top: 0px;
    left: 0px;

    background-color: red;
}

.drop-zone-result-item svg
{
    height: 10px;
    width: 10px;

    margin-right: 10px;
}

.grade-class-dropzone
{
    position: absolute;
    top: 20px;
    left: -40px;

    max-width: 50px;
    max-height: 10px;

    z-index: 2;
}

.drop-zone-result-wrapper
{
    height: 90px;
    width: 160px;

    position: absolute;
    top: -31px;
    left: -50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.drop-zone-result-item
{
    height: 30px;
    width: 95%;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 7px;

    background: linear-gradient(#00B9F8, #022857);

    padding-left: 20px;

    position: relative;
}

.drop-zone-result-item-more
{
    height: 30px;
    width: 95%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    border-radius: 7px;

    background: linear-gradient(#00B9F8, #022857);

    padding-left: 20px;

    position: relative;

    cursor: pointer;

    border-bottom-left-radius: 0px;
}

.drop-zone-result-item-2
{
    height: 30px;
    width: 90%;

    align-self: start;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    border-radius: 7px;

    background: linear-gradient(#02202a, #02162f);

    padding-left: 20px;

    position: relative;
}

.center
{
    border-radius: 0px;
}

.last
{
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.profile-first img
{
    height: 40px;
    width: 40px;

    position: absolute;
    left: -20px;
    top: -6px;

    border-radius: 100px;

    z-index: 1;
    background-color: white;
}

.profile-second img
{
    height: 25px;
    width: 25px;

    position: absolute;
    left: -13px;
    top: 5px;

    border-radius: 100px;

    background-color: white;
}

.drop-zone-badges-list svg
{
    height: 10px;
    width: 10px;
}

.drop-zone-player-size
{
    height: 100%;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 10px;

    max-width: 40px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: center;
}

.drop-zone-player-size p
{
    height: 1px;
}


.drop-zone-player-badges p
{
    height: 1px;
}

.drop-zone-player-badges
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.drop-zone-badges-list-name
{
    height: 15px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: 70px;
    font-size: 10px;
}

.drop-zone-badges-list
{
    height: 15px;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    padding-right: 10px;

    padding-left: 7px;
    padding-bottom: 3px;
}

.player-popup-information-wrapper
{
    height: 150px;
    width: 270px;

    border-radius: 25px;

    z-index: 2;

    position: absolute;
    top: 40px;
    left: -60px;

    border: 1px solid white;
    background-color: #1C1C1C;
}

.player-popup-information-profile
{
    height: 35px;
    width: 35px;

    border-radius: 100px;

    background-color: white;
}

.player-popup-information-top-wrapper
{
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    padding: 5px;
}

.player-popup-information-column-wrapper
{
    height: 100%;
    width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 10px;

    color: white;
}

.drop-zone-player-stars
{
    position: absolute;
    top: 22px;
    left: -35px;

    max-width: 40px;
    max-height: 20px;

    padding-top: 10px;
}

.player-popup-information-column-wrapper p
{
    height: 5px;
}

.player-popup-information-bottom-wrapper
{
    height: 80px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
}

.player-popup-information-bottom-wrapper table
{
    width: 200px;
    height: 70%;
}

.player-popup-information-bottom-wrapper table td
{
    padding: 2px;
}

.player-popup-information-bottom-left
{
    text-align: left;   
    border-bottom: 1px solid white;

    font-size: 10px;

    min-width: 70px;

    color: #707070;
}

.player-popup-information-bottom-left-little
{
    text-align: left;   
    border-bottom: 1px solid white;

    font-size: 10px;

    min-width: 20px;

    color: #707070;
}

.player-popup-information-bottom-right
{
    text-align: right;   
    border-bottom: 1px solid white;

    font-size: 10px;

    min-width: 170px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    max-width: 100px;
    
    color: white;
}

.player-popup-information-column-start
{
    max-height: 15px;
    max-width: 70px;

    margin-bottom: 10px;
}