*
{
    margin: 0px;
    padding: 0px;
}

.archive-item-wrapper
{
    height: 350px;
    width: 247px;
    
    min-height: 350px;
    margin-top: 30px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    border-bottom: 4px solid #252832;

    position: relative;
}

.archive-item-title-wrapper
{
    height: 50px;
    width: 220px;

    padding: 10px;

    color: white;

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    border: 2px solid #0350AE;
    border-radius: 30px;
}

.archive-item-wrapper button
{
    position: absolute;
    top: -10px;
    right: 0px;

    border: none;
    background: none;

    color: red;
}

.archive-item-wrapper button:focus
{
    outline: none;
}

.archive-item-title-wrapper p
{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.archive-item-footer-wrapper
{
    height: 30px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    color: #00B9F8;
}

.loader
{
    position: absolute;
    top: 30%;
    left: 26%;

    border: 16px solid #f3f3f3;
    border-top: 16px solid rgb(10, 209, 10);
    border-radius: 50%;

    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.loaderDelete
{
    position: absolute;
    top: 30%;
    left: 26%;

    border: 16px solid #f3f3f3;
    border-top: 16px solid red;
    border-radius: 50%;

    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin
{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}