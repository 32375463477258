.pdf-form-wrapper
{
    height: 300px;
    width: 250px;

    background-color: #1C1C1C;

    border-radius: 10px;

    position: absolute;
    bottom: 60px;
    right: 390px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border: 1px solid white;

    z-index: 2;
}

.button-enable
{
    height: 40px;
    width: 100px;

    border-radius: 100px;

    background: none;

    border: 2px solid grey;

    color: white;

    text-align: center;

    margin-bottom: 15px;
    border: 2px solid #0250AE;
}

.button-enable:focus
{
    outline: none;
}

.button-desable:focus
{
    outline: none;
}

.button-desable
{
    height: 40px;
    width: 100px;

    border-radius: 100px;

    background: none;

    border: 2px solid grey;

    color: white;

    text-align: center;

    margin-bottom: 15px;
    border: 2px solid grey;
}

.pdf-form-wrapper input
{
    width: 90%;
    height: 50px;

    margin-bottom: 40px;

    border: none;
    background: none;

    border-bottom: 1px solid #0250AE;

    color: white;
}

.pdf-form-wrapper input:focus
{
    outline: none;
    border-bottom: 1px solid rgb(14, 168, 14);
}

.notChrome
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    color: white;

    padding: 50px;
}