@media only screen and (max-width: 600px) {
    #forgotPassword_form {
        width: 80vw;
    }
    #forgotPassword_formContainer {
        margin-right: 0%;
    }
}

@media only screen and (min-width: 600px) {
    #forgotPassword_form {
        width: 20vw;
    }
    #forgotPassword_formContainer {
        margin-right: 25%;
    }
}