@media only screen and (max-width: 600px) {
    #login_form {
        width: 80vw;
    }
    #login_formContainer {
        margin-right: 0%;
    }
}

@media only screen and (min-width: 600px) {
    #login_form {
        width: 20vw;
    }
    #login_formContainer {
        margin-right: 25%;
    }
}