*
{
    margin: 0px;
    padding: 0px;
}

.commentaire-list-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    padding-bottom: 10px;
    
    overflow: scroll;
}

.commentaire-list-title-wrapper
{
    height: 70px;
    width: 247px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 8px;

    color: white;
}

.commentaire-list-item-wrapper
{
    height: 80%;
    width: 100%;

    overflow: scroll;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    padding-top: 5px;
}