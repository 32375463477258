*
{
    margin: 0px;
    padding : 0px;
}

.board-wrapper
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.not-chrome-disclamer
{
    height: 100vh;
    width: 100vw;

    position: absolute;
    top: 0px;
    left: 0px;

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #00000080;
}

.not-chrome-disclamer > div
{
    height: auto;
    width: 500px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 20px;

    color: white;

    background-color: #171717;
}

.not-chrome-disclamer > div > button
{
    height: 30px;
    width: 90px;

    border: none;
    background: none;

    border-radius: 5px;

    color: white;

    margin-top: 50px;
    margin-bottom: 40px;

    background-color: cornflowerblue;
}

.not-chrome-disclamer > div > p
{
    max-width: 80%;
}

.not-chrome-disclamer > div > img
{
    height: 100px;
    width: 100px;

    margin-bottom: 50px;
    margin-top: 50px;
}