*
{
  margin: 0px;
  padding: 0px;
}

.menu-wrapper
{
  height: 85%;
  width: 102px;

  position: absolute;
  bottom: 0px;
  left: 0px;

  border: none;

  background-color: #1C1C1C;
}


.menu-wrapper-close
{
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  border-top: 1px solid white;
  border-right: 1px solid white;
}

.menu-wrapper-open
{
  border-top: 1px solid white;
}

.menu-wrapper ul
{
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  list-style: none;
}