.badges-list-wrapper
{
    overflow: scroll;
}

.badges-list-title-wrapper
{
    height: 70px;
    width: 247px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 8px;

    color: white;
}

.badges-list-badges-wrapper
{
    height: 400px;
    width: 100%;

    display: grid;
    grid-column-gap: 18px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(4, 50px);
    grid-template-rows: repeat(4, 50px);
    padding: 10px 25px;

    overflow: scroll;

    overflow: scroll;
}

.badges-create-badge-wrapper
{
    height: 240px;
    width: 100%;

    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.badges-choice-wrapper
{
    height: 70px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    padding-left: 25px;
}

.badges-choice-wrapper p
{
    height: 10px;
}

.badges-choice-item-wrapper
{
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.badges-choice-item-wrapper svg 
{
    height: 25px;
    width: 25px;

    fill: #0350AE;

    margin-right: 15px;
}

.badges-legende-wraper
{
    height: 200px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.badget-legende-box-wraper
{
    height: 160px;
    width: 290px;

    border-radius: 30px;

    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    background-color: #2D2D2D;
}

.badget-legende-box-wraper input
{
    height: 60px;
    width: 100%;

    text-align: center;

    align-self: center;

    border: none;
    background: none;

    color: white;
}

.badget-legende-box-wraper input:focus
{
    outline: none;
    border: none;
}

.badget-legende-box-wraper button
{
    height: 40px;
    width: 100px;

    align-self: flex-end;

    margin-right: 25px;

    color: white;

    border-radius: 30px;

    border: none;
}

.badget-legende-box-button-disable
{
    background: grey;
}

.badget-legende-box-button-enable
{
    background: linear-gradient(#00C2FF, #044CAB);
}

.badget-legende-box-wraper button:focus
{
    outline: none;
    border: none;
}