.player-add-item-wrapper
{
    height: 66px;
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;

    background-color: #1C1C1C;

    margin-bottom: 9px;

    padding: 0px 12px;
}

.player-add-item-info-wrapper
{
    height: 100%;
    width: 881px;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px;

    background-color: #2D2D2D;
}

.player-add-item-info-wrapper p
{
    max-width: 90px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profile-item
{
    height: 50px;
    width: 50px;

    border-radius: 100px;

    margin-left: 20px;
    margin-right: 35px;

    background-color: white;
}

.grade
{
    max-height: 15px;
    max-width: 70px;

    border: none;

}

.player-add-item-info-wrapper p
{
    margin-right: 35px;
}

.player-add-item-info-wrapper-selected
{
    border: 1px solid #76DE01;
}

.player-add-item-button-wrapper
{
    height: 100%;
    width: 58px;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    background-color: #2D2D2D;
}

.player-add-item-button-wrapper button
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    background: none;
}

.player-add-item-button-wrapper button:focus
{
    border: none;
    background: none;
    outline: none;
}

.player-add-item-button-wrapper button img
{
    height: 25px;
    width: 25px;
}