*
{
    margin: 0px;
    padding: 0px;
}

.badges-item-droppable-wrapper
{
    height: 50px;
    width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    overflow: hidden;
}

.badges-item-droppable-wrapper svg
{
    height: 20px;
    width: 20px;
}

.badges-item-droppable-wrapper p
{
    height: 5px;
    font-size: 14px;
}