*
{
    margin: 0px;
    padding: 0px;
}

.soccer-field-input
{
   height: 51px;
   width: 328px;

   border-radius: 100px;
   border: 2px solid #0350AE;
    color: white;

   background: none;

   text-align: center;
}

.soccer-field-input:focus
{
    outline: none;
}

.soccer-field-pdf-button
{
    height: 50px;
    width: 40px;

    position: absolute;

    right: 380px;
    bottom: 10px;

    z-index: 5;
}