*
{
    margin: 0px;
    padding: 0px;
}

.composition-item-wrapper
{
    height: 300px;
    width: 247px;
    
    padding-bottom: 30px;
    margin-top: 30px;

    border-bottom: 4px solid #252832;
}