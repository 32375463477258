*
{
    margin: 0px;
    padding: 0px;
}

.commentaire-item-wrapper
{
   height: auto;
   width: 288px;

   max-width: 90%;

   border-radius: 30px;

   color: white;

   display: flex;
   justify-content: flex-start;
   align-items: center;

   padding: 5px 20px;

   margin-bottom: 20px;

   background-color: #131313;
}

.commentaire-item-wrapper p
{
    word-break: break-all;
}

.commentaire-item-wrapper button
{
    border: none;
    background: none;

    outline: none;

    margin-right: 15px;
}

.commentaire-item-wrapper button svg
{
    height: 20px;
    width: 20px;

    fill: grey;
}

.commentaire-item-wrapper button svg:hover
{
    fill: red;
}

.commentaire-item-wrapper button:focus
{
    outline: none;
}