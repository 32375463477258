*
{
    margin: 0px;
    padding : 0px;
}

.popup-add-wrapper
{
    height: 606px;
    width: 989px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-63%, -50%);

    border: 1px solid white;
    border-radius: 10px;

    z-index: 2;
    
    background-color: #1C1C1C;
}

.popup-add-header-wrapper
{
    height: 70px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    border-bottom: 1px solid white;
}

.popup-add-search-wrapper
{
    height: 100%;
    width: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-add-button-wrapper
{
    height: 100%;
    width: 30%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-right: 20px;
}

.popup-add-button-enable
{
    height: 45px;
    width: 113px;

    border-radius: 5px;

    border: none;

    color: white;
    background: linear-gradient(#00C2FF, #044CAB);
}

.popup-add-button-enable:focus
{
    outline: none;
    border: none;
}

.popup-add-button-disable
{
    height: 45px;
    width: 113px;

    border-radius: 5px;

    border: none;

    color: white;
    background-color: grey;
}

.popup-add-button-disable:focus
{
    outline: none;
    border: none;
}

.popup-close-button
{
    height: 45px;
    width: 113px;

    border-radius: 5px;

    border: none;

    margin-left: 8px;

    color: white;
    background-color: red;
}

.popup-close-button:focus
{
    outline: none;
    border: none;
}

.popup-player-list-wrapper
{
    height: 85%;
    width: 100%;

    box-sizing: border-box;

    padding-top: 85px;

    overflow: scroll;

    position: relative;

    margin-top: 9px;
}

.popup-more-button
{
    height: 40px;
    width: 100px;

    position: absolute;
    bottom: -20px;
    left: 45%;

    border-radius: 30px;
    border: none;

    background: linear-gradient(#00C2FF, #044CAB);

    color: white;
}

.popup-more-button:focus
{
    outline: none;
}

.custom-hits-wrapper
{
    height: auto;
    width: 100%;

    position: absolute;
    top: 75px;
    left: 0px;

    z-index: 5;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    background-color: #1C1C1C;

    box-sizing: border-box;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-hits
{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.custom-hit-profile
{
    height: 30px;
    width: 30px;

    border-radius: 100px;

    background-color: white;
}